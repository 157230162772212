import React, { Component } from 'react'
import ReactDOM from 'react-dom/client';
import axios from 'axios';

import './css/bootstrap.min.css';
import './css/main-body.css';
import './css/main-form.css';
import './css/main-footer.css';

import Footer from './components/Footer';

export default class App extends Component
{
    constructor(props)
    {
        // Props
        super(props);

        // State
        this.state = {

            // Stone collection format: [id, name, displayname_nl, displayname_fr, length, height, width_brick, height_brick]
            content_stone_collections: [],

            // Spacing types: [name, displayname_nl, displayname_fr, spacing_h_length, spacing_h_darken, spacing_v_length, spacing_v_darken]
            content_spacing_types: [],

            // Stone type format: [name, collection_id, displayname_nl, displayname_fr, article_id_strip, article_id_strip_corner, article_id_stone]
            content_stone_types: [],

            content_loaded: false,

            configurator_stone_collection: 0,
            configurator_spacing_type: 0,
        }

        // Translation
        this.translation_language_index = 0;
        if (window.location.origin === 'https://calculator.facade.stone-style.ebema.be')
        {
            this.translation_language_index = 1;
            document.documentElement.lang = 'fr-BE';
        }

        this.translation_nl = {};
        this.translation_fr = {};
        this.translate = this.translate.bind(this);

        // Content
        this.content_stone_collections_loaded = false;
        this.content_stone_types_loaded = false;
        this.content_spacing_types_loaded = false;
        this.content_translations_loaded = false;

        this.content_get_stone_collections = this.content_get_stone_collections.bind(this)
        this.content_get_stone_types = this.content_get_stone_types.bind(this);
        this.content_get_spacing_types = this.content_get_spacing_types.bind(this);
        this.content_get_translations = this.content_get_translations.bind(this);
        this.content_check_loaded = this.content_check_loaded.bind(this);

        // Startup
        this.startup = this.startup.bind(this);

        // Configurator
        this.configurator_spacing_type = this.configurator_spacing_type.bind(this);

        // Debug
        this.debug = true;

        // IP
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
            this.ip = 'http://127.0.0.1:8000';
        else
            this.ip = window.location.origin;
    }

    // -------------------------------------------------------------------

    // Translate
    translate(key)
    {
        switch (this.translation_language_index)
        {
            case 0: return this.translation_nl[key];
            case 1: return this.translation_fr[key];
            default: return this.translation_nl[key];
        }
    }
    
    // -------------------------------------------------------------------

    componentDidMount()
    {
        if (this.debug === true)
            console.log('Initiate loading');

        this.content_get_stone_collections();
        this.content_get_stone_types();
        this.content_get_spacing_types();
        this.content_get_translations();
    };

    // -------------------------------------------------------------------

    // Content get stone collections
    content_get_stone_collections()
    {
        const get_stone_collections = async () => {
            try
            {
                let experimental = '';
                if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
                    experimental = '_experimental';

                const response = await axios.get(this.ip+'/backend/api/get_content_stone_collections'+experimental);

                this.setState({
                    content_stone_collections: response.data
                }, () => {
                    if (this.debug === true)
                    {
                        console.log('Content collections');
                        console.log(this.state.content_stone_collections);
                    }

                    this.content_stone_collections_loaded = true;
                    this.startup();
                });
            }

            catch(error)
            {
                console.log(error);
            }
        }
        
        get_stone_collections();
    }

    // Content get stone types
    content_get_stone_types()
    {
        const get_stone_types = async () => {
            try
            {
                let experimental = '';
                if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
                    experimental = '_experimental';

                const response = await axios.get(this.ip+'/backend/api/get_content_stone_types'+experimental);

                this.setState({
                    content_stone_types: response.data,
                }, () => {
                    if (this.debug === true)
                    {
                        console.log('Content stone types');
                        console.log(this.state.content_stone_types);
                    }

                    this.content_stone_types_loaded = true;
                    this.startup();
                });
            }

            catch(error)
            {
                console.log(error);
            }
        }

        get_stone_types();
    }

    // Content get spacing types
    content_get_spacing_types()
    {
        const get_spacing_types = async () => {
            try
            {
                let experimental = '';
                if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
                    experimental = '_experimental';

                const response = await axios.get(this.ip+'/backend/api/get_content_spacing_types'+experimental);
                
                this.setState({
                    content_spacing_types: response.data
                }, () => {
                    if (this.debug === true)
                    {
                        console.log('Content spacing types');
                        console.log(this.state.content_spacing_types);
                    }

                    this.content_spacing_types_loaded = true;
                    this.startup();
                });
            }

            catch(error)
            {
                console.log(error);
            }
        }
        
        get_spacing_types();
    }

    // Content get translations
    content_get_translations()
    {
        const get_translations = async() => {
            try
            {
                const response = await axios.get(this.ip+'/backend/api/get_content_translations');
                
                this.setState({
                    content_translations: response.data
                }, () => {
                    if (this.debug === true)
                    {
                        console.log('Content translations');
                        console.log(this.state.content_translations)
                    }

                    // Build translation dictionary
                    for (let i = 0; i < this.state.content_translations.length; i++)
                    {
                        let key = this.state.content_translations[i][0];
                        this.translation_nl[key] = this.state.content_translations[i][1];
                        this.translation_fr[key] = this.state.content_translations[i][2];
                    }
                    
                    this.content_translations_loaded = true;
                    this.startup();
                });
            }
            
            catch(error)
            {
                console.log(error);
            }
        }

        get_translations();
    }

    // Content check loaded
    content_check_loaded()
    {
        // Normal mode
        if (this.content_stone_collections_loaded === true
        && this.content_stone_types_loaded === true
        && this.content_spacing_types_loaded === true
        && this.content_translations_loaded === true)
        {
            if (this.debug === true)
                console.log('Content true');
            
            return true;
        }
        else
        {
            if (this.debug === true)
                console.log('Content false');

            return false;
        }
    }

    // Startup
    startup()
    {
        if (this.content_check_loaded() === true)
        {
            if (this.state.content_loaded === false)
            {
                this.setState({
                    content_loaded: true
                });
            }
        }
    }

    // Configurator spacing type
    configurator_spacing_type(index)
    {
        if (this.state.content_loaded === true)
        {
            this.setState({
                configurator_spacing_type: index
            });
        }
    }

    // Render
    render()
    {
        // Calculator
        return (
            <React.Fragment>
                <div className="body-footer">
                    { this.state.content_loaded === true &&
                        <Footer
                            translation_language_index={this.translation_language_index}
                            content_stone_collections={this.state.content_stone_collections}
                            content_stone_types={this.state.content_stone_types}
                            content_spacing_types={this.state.content_spacing_types}        
                            configurator_stone_collection={this.state.configurator_stone_collection}
                            configurator_spacing_type={this.state.configurator_spacing_type}
                            function_configurator_spacing_type={this.configurator_spacing_type}
                            function_translate={this.translate} />
                    }
                </div>
            </React.Fragment>
        );
    }
}

const app = ReactDOM.createRoot(document.getElementById('app'));
app.render(
    <App />
);